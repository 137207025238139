import { SRFBridge } from 'srf-native-bridge';
import ClientStorage from '@fec/frontend/foundation/client/storage';
import { getConfig } from '@fec/assets/js/utils/config';
import { Tools } from '../../common/js/analytics/Tools';
import {
  isTeaserPlaying,
  markTeaserAsNotPlaying,
  markTeaserAsPlaying,
} from '@fec/frontend/modules/content/teaser-ng/teaser-ng';
import {
  isMediumPlaying,
  markMediumAsPlaying,
  markMediumAsStopped,
} from '@fec/frontend/modules/content/medium/medium';
import { readSubscriptionList } from '@fec/assets/js/utils/meta-tags';
import {
  activateAllTrackers,
  activateCategory,
} from '../../common/js/consentManager';
import {
  APP_ID_RECEIVED,
  JUMP_TO_ANCHOR,
  SET_IMAGE_GALLERY_INDEX,
  SET_USER_LOCATION,
  triggerEvent,
} from '@fec/assets/js/utils/event';

let webtrekk = window?.SRF_BRIDGE_DATA?.webtrekk;
const config = getConfig();
const isLegacyApp = typeof window.nativebridge !== 'undefined';
export const PREVENT_LINK_HANDLING_ATTRIBUTE = 'preventBridgeHandling';

if (webtrekk) {
  let storedData = ClientStorage.getItemJsonParsed('srf:analytics:mod:app');
  webtrekk.srg_mod1 = getModData(storedData, 'mod1');
  webtrekk.srg_mod2 = getModData(storedData, 'mod2');
  webtrekk.srg_mod3 = getModData(storedData, 'mod3');
  webtrekk.srg_mod4 = getModData(storedData, 'mod4');
  ClientStorage.removeItem('srf:analytics:mod:app');
  Object.keys(webtrekk).forEach(function (key) {
    webtrekk[key] === 'undefined' && delete webtrekk[key];
  });

  const trackingTools = new Tools();
  webtrekk.content_elapsed_since_publication =
    trackingTools.getDaysSincePublished(
      webtrekk.content_publication_date_concatenated,
    );
}

function getModData(storedData, key) {
  let result = 'undefined';
  if (storedData) {
    if (storedData.hasOwnProperty('srg_' + key)) {
      result = storedData['srg_' + key];
    } else if (storedData.hasOwnProperty('srf_' + key)) {
      result = storedData['srf_' + key];
    }

    if (storedData.hasOwnProperty('srg_override_' + key)) {
      result = storedData['srg_override_' + key];
    }
  }

  return decodeURIComponent(decodeURIComponent(result)).replace('+', ' ');
}

const isElementPlaying = ($element) => {
  if ($element.is('.js-teaser-ng')) {
    return isTeaserPlaying($element[0]);
  } else {
    return isMediumPlaying($element);
  }
};

const setElementPlaying = ($element) => {
  if (!isLegacyApp) {
    if ($element.is('.js-teaser-ng')) {
      markTeaserAsPlaying($element[0]);
    } else {
      markMediumAsPlaying($element);
    }
  }
};

const setElementStopped = ($element) => {
  if ($element.is('.js-teaser-ng')) {
    markTeaserAsNotPlaying($element[0]);
  } else {
    markMediumAsStopped($element);
  }
};

SRFBridge.addListener('DeviceInfo', ({ payload }) => {
  if (isLegacyApp) {
    giveConsentToEverything();
  }
  try {
    ClientStorage.setItemJsonStringified('sg_app_data', {
      ceid: payload.deviceId,
      navigation_app_site_name: payload.siteName,
    });
    const event = new CustomEvent('setDeviceInfo', {});
    document.dispatchEvent(event);
  } catch (e) {
    //oh no
  }
});

SRFBridge.addListener('MediaPlaybackStatus', ({ payload }) => {
  let $element = $(`a[data-assetid="${payload.urn}"]`);
  if (!$element.length) {
    $element = $(`a[data-asset-urn="${payload.urn}"]`);
  }

  if ($element.length) {
    if (payload.playbackState === 'PLAYING') {
      setElementPlaying($element);
    } else {
      setElementStopped($element);
    }
  }
});

SRFBridge.addListener('SetTextSize', ({ payload }) => {
  const { scaleFactor } = payload;
  document.documentElement.style.webkitTextSizeAdjust = scaleFactor * 100 + '%';
});

SRFBridge.addListener('SetImageGalleryIndex', ({ payload }) => {
  const { galleryId, index } = payload;
  // trigger an event so that the CMS-rendered gallery (image-carousel) and the
  // aron-rendered gallery (image-gallery) can react to the change
  triggerEvent(SET_IMAGE_GALLERY_INDEX, { galleryId, index });
});

SRFBridge.addListener('JumpToAnchor', ({ payload }) => {
  const { anchor } = payload;
  ClientStorage.setItem('srf:anchor', anchor);
  triggerEvent(JUMP_TO_ANCHOR, { anchor });
});

SRFBridge.addListener('SetUserLocation', ({ payload }) => {
  ClientStorage.setItemJsonStringified('srf:userLocation', payload);
  triggerEvent(SET_USER_LOCATION, payload);
});

SRFBridge.addListener(
  'HideArticleTeasersByContentCategories',
  ({ payload }) => {
    const { categories = [] } = payload;

    categories.forEach((category) => {
      const liElements = document.querySelectorAll(
        'a[data-content-categories*="' + category + '"]',
      );

      Array.from(liElements)
        .map((a) => a.closest('li'))
        .forEach((li) => {
          li.style.display = 'none';
        });
    });
  },
);

SRFBridge.addListener('Initialize', ({ payload }) => {
  const {
    scaleFactor = null,
    appId = '',
    deviceId = '',
    usercentricsUserSessionData = null,
  } = payload;

  if (scaleFactor !== null) {
    document.documentElement.style.webkitTextSizeAdjust =
      scaleFactor * 100 + '%';
  }

  // save app-id to local storage and send an event, to cover all use cases
  // (listeners were added before/after the event was sent)
  ClientStorage.setItem('srf:appId', appId);
  triggerEvent(APP_ID_RECEIVED, { appId });

  try {
    if (usercentricsUserSessionData !== null) {
      //add global variable injected from app before loading usercentrics script
      window.UC_UI_USER_SESSION_DATA = JSON.parse(
        atob(usercentricsUserSessionData),
      );

      const rulesetId =
        appId === 'rtr-app' ? 'EbJMk46ytrh9y5' : 'v6OFae9a5-aJ32';

      $('body').append(
        `<script id="usercentrics-cmp" src="https://app.usercentrics.eu/browser-ui/latest/loader.js" data-ruleset-id="${rulesetId}" async></script>`,
      );
    } else {
      //we're dealing with an app that does not support cmp
      giveConsentToEverything();
    }
    ClientStorage.setItemJsonStringified('sg_app_data', {
      ceid: deviceId,
      navigation_app_site_name: appId,
    });
    const event = new CustomEvent('setDeviceInfo', {});
    document.dispatchEvent(event);
  } catch (e) {
    //oh no
  }
});

SRFBridge.init({
  title: webtrekk?.content_title_pretty,
  weburl: window?.SRF_BRIDGE_DATA?.weburl,
  shortLead: window?.SRF_BRIDGE_DATA?.shortLead,
  type: webtrekk?.content_page_type,
  webtrekk: webtrekk,
  subscriptionLists: readSubscriptionList(),
});

const giveConsentToEverything = () => {
  //we're dealing with an app that does not support cmp
  //activate functional embeds
  activateCategory('functional');

  //activate all trackers that are present in webview
  activateAllTrackers();
};

if (SRFBridge.isRunningInApp() && config.CLICKJACKING_ENABLED) {
  $(document).on('click', 'a', (event) => {
    if (typeof event.currentTarget.dataset.bridgePayload !== 'undefined') {
      event.preventDefault();
      event.stopPropagation();
      const $target = $(event.currentTarget);

      if (isElementPlaying($target)) {
        const assetUrn = $target.data('assetid') || $target.data('assetUrn');
        SRFBridge.mediaPlaybackStatus('STOPPED', assetUrn);
        setElementStopped($target);
        return;
      } else {
        setElementPlaying($target);
        const bridgePayload = JSON.parse(
          event.currentTarget.dataset.bridgePayload,
        );
        switch (bridgePayload.EventType) {
          case 'Video':
            SRFBridge.playVideo(bridgePayload.EventPayload);
            return;
          case 'Audio':
            SRFBridge.playAudio(bridgePayload.EventPayload);
            return;
          case 'Livestream':
            SRFBridge.playLivestream(bridgePayload.EventPayload);
            return;
        }
      }
    }
    //no further click-jacking for legacy app
    if (isLegacyApp) {
      return;
    }

    if (event.currentTarget.dataset[PREVENT_LINK_HANDLING_ATTRIBUTE]) {
      return;
    }

    const url = event.currentTarget.href;
    if (!url) {
      return;
    }
    if (
      event.currentTarget.hasAttribute('href') &&
      event.currentTarget.getAttribute('href').indexOf('#') === 0
    ) {
      return;
    }
    //special use case for alchemer popup
    if (
      event.currentTarget.hasAttribute('href') &&
      event.currentTarget
        .getAttribute('href')
        .toLowerCase()
        .indexOf('javascript:') === 0
    ) {
      return;
    }

    event.preventDefault();
    const urn = event.currentTarget.dataset.urn;
    const title = event.currentTarget.dataset.title ?? '';
    if (urn?.includes(':article:')) {
      SRFBridge.openArticle(urn, url, title);
    } else if (urn?.includes(':landingpage:')) {
      SRFBridge.openLandingPage(urn, url, title);
    } else {
      SRFBridge.openLink(url, title);
    }
  });
}
